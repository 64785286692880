html,
body,
#app {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.navigator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.bg-menu {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 12%;
    height: 3%;
    font-size: 2vh;
    padding-top: 5px;
    text-align: center;
    border-radius: 20px;
    background-color: whitesmoke;
}

.bg-menu a {
    margin-right: 10px;
    text-decoration: none;
    color: black;
    pointer-events: auto;
    cursor: pointer;
}

.bg-menu a:hover {
    background-color: wheat;
    border-radius: 10px;
}

.footer {
    position: absolute;
    bottom: 1%;
    width: 100%;
    height: 3%;
    font-size: 1.5vh;
    padding-top: 5px;
    text-align: center;
    color: white;
}